<template>
  <div class="container">
    <div class="container_content">
      <!-- 左侧图片 -->
      <div class="container_content_left">
        <img :src="currentImgSrc" alt="">
        <div class="container_content_left_imgs">
          <img v-for="(img, index) in imgList" :key="index" :src="img.imageUrl" @click="changeImg(img.imageUrl)" :class="{ 'container_content_left_imgs_isSelected': img === currentImgSrc }" alt="">
        </div>
      </div>

      <!-- 右侧详情 -->
      <div class="container_content_right">
        <div class="container_content_right_one">
          <div class="container_content_right_one_left">
            {{ product.productName }}
          </div>
          <div class="container_content_right_one_property">
            · {{ product.property === '0' ? 'Semi - finished Product' : 'Finished Product' }}
          </div>

          <!--          <div class="container_content_right_one_right">-->
          <!--            <div class="container_content_right_one_right_right1">-->
          <!--              Unit Price-->
          <!--            </div>-->
          <!--            <div class="container_content_right_one_right_right2" v-if="product.supplierProductSkuList">-->
          <!--              {{ (product.supplierProductSkuList !== undefined && product.supplierProductSkuList.length === 0) ? '-' : (product.supplierProductSkuList[0].skuPrice ? product.supplierProductSkuList[0].skuPrice.toFixed(2) : '- ') }} CNY-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="container_content_right_one_btn" @click="toSendRequest" v-if="userType == 'buyer'">
            Send customize request
          </div>
        </div>
        <div class="container_content_right_content">
          <div class="container_content_right_content_two">
            <div class="container_content_right_content_two_introduction">
              {{ product.productIntroduction }}
            </div>

            <div class="container_content_right_content_two_left">

              <!--                            <img src="../../assets/images/home/carbonFootprint.png" alt="">-->
              <!--                            <div class="container_content_right_content_two_left_number">-->
              <!--                              {{ product.carbonFootprintSum }}-->
              <!--                            </div>-->
              <!--                            <div class="container_content_right_content_two_left_unit">-->
              <!--                                g CO2-->
              <!--                            </div>-->
            </div>
            <!-- <div class="container_content_right_content_two_right" @click="toSendRequest"
                            v-if="userType == 'buyer'">
                            Send customize request
                        </div> -->
            <!-- 如果是自己的商品，显示编辑按钮，跳转上传产品的页面 -->
            <!--                        <div class="container_content_right_content_two_right" @click="toAddProduct"-->
            <!--                            v-if="userType == 'ProductOwner'">-->
            <!--                            Edit Product-->
            <!--                        </div>-->
          </div>

          <!-- 产品数据 -->
          <div class="container_content_right_content_sku">
            <div :class="['container_content_right_content_sku_item', selectedIndex == index ? 'container_content_right_content_sku_isSelected' : '']" v-for="(item, index) in productData" :key="index" @click="changeIndex(index)">
              {{ item['sukName'] }}
            </div>
          </div>
          <div class="container_content_right_content_data">
            <div class="container_content_right_content_data_item" v-for="(value, key) in productData[selectedIndex]" :key="key" v-show="key in itemNames">
              <div class="container_content_right_content_data_item_key">{{ itemNames[key] }}</div>
              <div class="container_content_right_content_data_item_value">{{ value }}</div>
            </div>
          </div>

          <div class="container_content_right_content_three">Origin</div>
          <div class="container_content_right_content_four">
            <div class="container_content_right_content_four_item" v-if="provinceName">{{ provinceName }}</div>
            <div class="container_content_right_content_four_item">low impact material</div>
          </div>
          <div class="container_content_right_content_five">
            <div class="container_content_right_content_five_title">
              Manufacturer Profile
            </div>
            <div style="display:flex;align-content: space-between;justify-content: space-between; align-items: center;">
              <div class="container_content_right_content_five_top">
                <div class="container_content_right_content_five_top_one">{{ product.supplier?.supplierName }}</div>
                <div class="container_content_right_content_five_top_two">
                  <img src="../../assets/images/product/safe.png" alt="">
                  <div class="container_content_right_content_five_top_two_content" v-if="product.supplier?.type === 'RZSJ'">Sustainable Company
                    Verified</div>
                </div>
              </div>
              <div class="container_content_right_content_five_bottom" v-if="product.supplier?.sustainableStatus === '1'">
                <div class="container_content_right_content_five_bottom_three">
                  <img src="../../assets/images/product/safe2.png" alt="">
                  <div class="container_content_right_content_five_bottom_three_content" v-if="product.supplier?.status === 1">Full Customiztion
                  </div>
                </div>
                <div class="container_content_right_content_five_bottom_three">
                  <img src="../../assets/images/product/safe2.png" alt="">
                  <div class="container_content_right_content_five_bottom_three_content" v-if="product.supplier?.status === 1">Certifictes Available
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- 供应商的信息 -->
          <div class="container_content_right_content_six">
            <div class="container_content_right_content_six_one">
              <img src="../../assets/images/product/china.png" alt="">
              <div class="container_content_right_content_six_one_content">{{ stationValue }}</div>
            </div>
            <div class="container_content_right_content_six_two">
              {{ product?.supplier?.loginYear }} YRS
            </div>
            <!--                        <div class="container_content_right_content_six_three">-->
            <!--                            {{ product.supplier?.companyProducts }}-->
            <!--                        </div>-->
          </div>
          <div class="container_content_right_content_seven">
            <div class="container_content_right_content_seven_item">
              <div class="container_content_right_content_seven_item_top">{{ lastResponseTimeStr }} Day</div>
              <div class="container_content_right_content_seven_item_bottom">Response time</div>
            </div>
            <div class="container_content_right_content_seven_item">
              <div class="container_content_right_content_seven_item_top">{{ transactions }}</div>
              <div class="container_content_right_content_seven_item_bottom">Transactions</div>
            </div>
            <div class="container_content_right_content_seven_item">
              <div class="container_content_right_content_seven_item_top">{{ onTimeDeliveryRate }}%</div>
              <div class="container_content_right_content_seven_item_bottom">On-time delivery rate</div>
            </div>
          </div>

          <!-- 资质图片 -->
          <div class="container_content_right_content_pictitle" v-if="picList.length > 0">
            Cerificates
          </div>
          <div class="container_content_right_content_eight">
            <img v-for="(pic, index) in picList" :src="pic.imageUrl" :key=index :alt="'certificate-' + pic.imageName" @click="showPic(pic)">
          </div>
        </div>
      </div>
    </div>
    <!--        显示图片弹出-->
    <el-dialog v-model="showPicVisible" title="">
      <img :src=picUrl alt="" style="height: 700px; width: 100%; object-fit: contain;">
      <el-button type="primary" @click="downLoad">DownLoad</el-button>
    </el-dialog>
    <!-- foot   -->
    <Foot />
  </div>
</template>

<script setup name="ProductDetail">
import { reactive, ref, toRefs } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import Foot from '../../components/Foot.vue'
import { getProductDetail } from '@/api/product'
import { getMaterialList, getSysDictList } from '@/api/user'
import { getDict } from '@/api/common'

const router = useRouter()
const route = useRoute()

// 用户身份 买家才显示询价按钮
const productId = route.query.productId
const userType = route.query.userType
// const userType = ref('buyer')
// const userType = ref('ProductOwner')
// 显示大图
const showPicVisible = ref(false)
// 图片地址
const picUrl = ref('')

const product = ref({
})
const query = reactive({ queryParams: { productId: '' } })
const { queryParams } = toRefs(query)

// 所属国家
const stationValue = ref()
const stationKey = ref(1)
const stationType = ref('eko_supplier_station')

// 所属省份
const provinceName = ref()

const lastResponseTimeStr = ref('0')
const transactions = ref('0')
const onTimeDeliveryRate = ref('0')

const picList = ref([])
// 获取字典值
const getDictData = async (type, key) => {
  const res = await getDict({ type: type, key: key })
  if (res.code === 200) {
    stationValue.value = res.data
  }
}

// const baseUrl = ref('http://localhost/dev-api')

// 切换图片
const imgList = ref([])
console.log('imgList:' + imgList)
// console.log('imgList:' + imgList.value[0].imageUrl)
const currentImgSrc = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png')

const changeImg = (img) => {
  currentImgSrc.value = img
}

// 跳转到定制页面
const toSendRequest = () => {
  router.push({
    path: 'sendCustomizeRequest',
    query: {
      productId: productId,
      index: selectedIndex.value
    }
  })
}

// 自己的产品，跳转上传商品的页面
const toAddProduct = () => {
  router.push({
    path: 'userCenter',
    query: {
      userType: 1
    }
  })
}

const productData = ref([{
  'Sku Name': 'Customized Color Box1',
  Unit: 'Piece',
  Material: 'Paper',
  'Unit specification size': '600mm * 400mm * 200mm',
  'Unit weight': '96g'
}, {
  'Sku Name': 'Customized Color Box2',
  Unit: 'Piece',
  Material: 'Paper',
  'Unit specification size': '200mm * 400mm * 200mm',
  'Unit weight': '60g'
}])

const itemNames = {
  // sukName: 'Name',
  // skuUnit: 'Unit',
  materialName: 'Material',
  weight: 'Weight (g)',
  specification: 'Specification'
}

const selectedIndex = ref(0)
const changeIndex = (index) => {
  selectedIndex.value = index
}

const productDetail = async () => {
  queryParams.value.productId = productId
  const result = await getProductDetail(queryParams.value)
  if (result?.code === 200) {
    product.value = result?.data
    imgList.value = result.data.productImageList
    productData.value = result.data.supplierProductSkuList.map(item => {
      // const materialId = item.materialId
      // const matchedMaterial = materials.value.find(material => material.id === materialId)
      const matchedUnit = units.value.find(unit => unit?.dictCode === item.skuUnit)
      // item.skuMaterial = matchedMaterial?.catalog2
      item.skuUnit = matchedUnit?.dictLabel
      return item
    })
    if (product.value.supplier.stationId !== null) {
      stationKey.value = product.value.supplier.stationId
    }
    if (product.value.supplier.provinceName !== null) {
      provinceName.value = product.value.supplier.provinceName
    }
    if (product.value.supplier.lastResponseTimeStr !== null) {
      lastResponseTimeStr.value = product.value.supplier.lastResponseTimeStr
    }
    if (product.value.supplier.transactions !== null) {
      transactions.value = product.value.supplier.transactions
    }
    if (product.value.supplier.onTimeDeliveryRate !== null) {
      onTimeDeliveryRate.value = product.value.supplier.onTimeDeliveryRate
    }
    getDictData(stationType.value, stationKey.value)
    picList.value = result.data.supplierImageList
    console.log(picList.value, 'picList')
    console.log('product.value.supplier.stationId:' + stationKey.value)
    currentImgSrc.value = imgList.value[0].imageUrl
  }
  console.log('productData', productData)
}

// 材质列表
const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  if (res.code === 200) {
    materials.value = res.data
  }
}
getMaterialsList()
productDetail()

// 单位列表
const units = ref([])
// 获取商品类别
const getUnitType = async () => {
  const res = await getSysDictList({ type: 'eko_jldw' })
  if (res.code === 200) {
    units.value = res.data
  }
}
getUnitType()

// 显示图片
const showPic = (pic) => {
  showPicVisible.value = true
  picUrl.value = pic.imageUrl
}

// 下载
const downLoad = () => {
  var url = picUrl.value
  var fileName = 'file.png'
  console.log(url)
  if (picUrl.value) {
    var index = picUrl.value.lastIndexOf('/')
    var str = picUrl.value.substring(index + 1, picUrl.value.length)
    fileName = str
  }
  fetch(url).then(res => res.blob().then(blob => {
    var a = document.createElement('a')
    var url = window.URL.createObjectURL(blob)
    var filename = fileName
    a.href = url
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(url)
  }))
}

</script>

<style lang="scss" scoped>
.container {
  background-color: #FFFFFF;
  line-height: 1;

  &_content {
    width: 1600px;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    &_left {
      width: 30%;
      font-size: 0;

      img {
        width: 480px;
        height: 480px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 6px;
      }

      &_imgs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        img {
          width: 80px;
          height: 80px;
          overflow: hidden;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 15px;
          cursor: pointer;
          opacity: 0.3;

          &:nth-child(5n) {
            margin-right: 0px;
          }
        }

        &_isSelected {
          opacity: 1 !important;
          box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, .5);
        }
      }
    }

    &_right {
      width: 66%;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-bottom: 200px;

      &_one {
        width: 100%;
        height: 110px;
        // line-height: 20px;
        border-radius: 10px 10px 0px 0px;
        background: linear-gradient(180deg, rgba(39, 174, 96, 0.1) 0%, rgba(39, 174, 96, 0) 100%);
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 1);
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &_left {
          margin-left: 50px;
          // flex: 1;
          height: 30px;
          color: rgba(24, 24, 24, 1);
          font-size: 30px;
          text-align: left;
          font-weight: bold;
          width: 320px;
          word-wrap: break-word;
          word-break: normal;
        }

        // 产品属性
        &_property {
          margin-right: auto;
          margin-left: 30px;
          color: rgba(39, 174, 96, .8);
          font-size: 16px;
          line-height: 30px;
          text-align: right;
        }

        &_right {
          display: flex;

          &_right1 {
            margin-right: 10px;
            // height: 30px;
            color: rgba(173, 173, 173, 1);
            font-size: 16px;
            text-align: right;
          }

          &_right2 {
            margin-right: 50px;
            // height: 30px;
            color: rgba(255, 151, 0, 1);
            font-size: 16px;
            text-align: right;
            font-weight: bold;
          }
        }

        &_btn {
          padding: 20px 40px 20px 40px;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 1);
          text-align: center;
          border: 1px solid rgba(39, 174, 96, 1);
          color: rgba(39, 174, 96, 1);
          font-size: 14px;
          font-weight: bold;
          margin-right: 50px;

          &:hover {
            cursor: pointer;
            background-color: rgba(39, 174, 96, 1);
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      &_content {
        padding: 0px 50px 50px 50px;

        &_two {
          margin-top: 10px;
          width: 100%;
          display: flex;
          align-items: center;

          &_introduction {
            margin-bottom: 10px;
            font-size: 18px;
            text-align: left;
            line-height: 1.5em;
          }

          &_left {
            flex: 1;
            display: flex;

            img {
              width: 20px;
              height: 20px;
            }

            &_number {
              margin-left: 10px;
              height: 20px;
              color: rgba(39, 174, 96, 1);
              font-size: 20px;
              text-align: right;
              font-weight: bold;
            }

            &_unit {
              height: 20px;
              color: rgba(39, 174, 96, 1);
              font-size: 20px;
              text-align: left;
            }
          }

          &_right {
            padding: 20px 40px 20px 40px;
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 1);
            text-align: center;
            border: 1px solid rgba(39, 174, 96, 1);
            color: rgba(39, 174, 96, 1);
            font-size: 14px;
            font-weight: bold;

            &:hover {
              cursor: pointer;
              background-color: rgba(39, 174, 96, 1);
              color: rgba(255, 255, 255, 1);
            }
          }
        }

        &_sku {
          padding: 30px 0 10px;
          // margin-top: 40px;
          border-top: 1px solid rgba(240, 240, 240, 1);
          display: flex;

          &_item {
            font-size: 13px;
            padding: 8px 10px;
            border-radius: 4px;
            border: 1px solid rgba(240, 240, 240, 1);
            margin-right: 10px;
          }

          &_isSelected {
            border: 1px solid rgba(39, 174, 96, 1);
            color: rgba(39, 174, 96, 1);
          }
        }

        &_data {
          // margin-top: 20px;
          padding: 30px 0 10px;
          border-bottom: 1px solid rgba(240, 240, 240, 1);
          display: grid;
          grid-template-columns: auto auto;
          align-items: start;

          &_item {
            display: grid;
            grid-template-columns: auto 220px;
            align-items: start;
            text-align: left;
            line-height: 1;
            margin-bottom: 10px;

            &_key {
              font-size: 14px;
              line-height: 16px;
              font-weight: 400;
              color: rgba(150, 150, 150, 1);
            }

            &_value {
              font-size: 16px;
              line-height: 1.5;
              font-weight: 400;
              color: rgba(24, 24, 24, 1);
              // margin-left: 20px;
              text-align: left;
            }
          }
        }

        &_three {
          width: 100%;
          margin-top: 30px;
          height: 16px;
          color: rgba(24, 24, 24, 1);
          font-size: 16px;
          text-align: left;
          font-weight: bold;
        }

        &_four {
          margin-top: 20px;
          padding-bottom: 30px;
          width: 100%;
          display: flex;
          border-bottom: 1px solid rgba(240, 240, 240, 1);

          &_item {
            margin-right: 10px;
            line-height: 20px;
            border-radius: 2px;
            background-color: rgba(39, 174, 96, 0.06);
            text-align: center;
            padding: 2px 4px 2px 4px;
            color: rgba(36, 89, 2, 0.8);
            font-size: 12px;
          }
        }

        &_five {
          width: 100%;
          margin-top: 20px;

          //display: flex;
          //justify-content: space-between;
          //align-items: center;
          &_title {
            text-align: left;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(24, 24, 24, 1);
          }

          &_top {
            display: flex;

            &_one {
              margin-right: 10px;
              height: 16px;
              color: rgba(24, 24, 24, 1);
              font-size: 16px;
              text-align: left;
              // font-weight: bold;
              display: flex;
            }

            &_two {
              margin-right: 5px;
              display: flex;

              img {
                width: 16px;
                height: 16px;
              }

              &_content {
                margin-left: 5px;
                line-height: 16px;
                color: rgba(39, 174, 96, 1);
                font-size: 14px;
                text-align: left;
              }
            }
          }

          &_bottom {
            display: flex;

            &_three {
              margin-left: 5px;
              display: flex;

              img {
                width: 16px;
                height: 16px;
              }

              &_content {
                margin-left: 5px;
                line-height: 16px;
                color: rgba(150, 150, 150, 1);
                font-size: 12px;
                text-align: left;
              }

              &:first-child {
                margin-right: 20px;
              }
            }
          }
        }

        &_six {
          margin-top: 20px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(240, 240, 240, 1);
          padding-bottom: 30px;

          &_one {
            display: flex;
            background-color: rgba(248, 248, 248, 1);
            padding: 5px;
            border-radius: 2px;
            align-items: center;

            img {
              width: 30px;
              height: 20px;
            }

            &_content {
              margin-left: 5px;
              border-radius: 2px;
              text-align: center;
              color: rgba(24, 24, 24, 1);
              font-size: 12px;
              line-height: 1;

            }
          }

          &_two {
            margin-left: 10px;
            line-height: 20px;
            border-radius: 2px;
            background-color: rgba(248, 248, 248, 1);
            text-align: center;
            color: rgba(24, 24, 24, 1);
            font-size: 12px;
            padding: 5px 10px;
          }

          &_three {
            margin-left: 10px;
            line-height: 20px;
            border-radius: 2px;
            background-color: rgba(248, 248, 248, 1);
            text-align: center;
            color: rgba(24, 24, 24, 1);
            font-size: 12px;
            padding: 5px 10px;
          }
        }

        &_seven {
          margin-top: 30px;
          display: flex;
          border-bottom: 1px solid rgba(240, 240, 240, 1);
          padding-bottom: 30px;

          &_item {
            margin-right: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &_top {
              line-height: 14px;
              color: rgba(24, 24, 24, 1);
              font-size: 14px;
              font-weight: bold;
            }

            &_bottom {
              margin-top: 15px;
              line-height: 12px;
              color: rgba(150, 150, 150, 1);
              font-size: 12px;
            }
          }
        }

        &_pictitle {
          text-align: left;
          margin-bottom: 10px;
          margin-top: 20px;
          font-size: 16px;
          font-weight: bold;
          color: rgba(24, 24, 24, 1);
        }

        &_eight {
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          align-items: flex-start;

          img {
            width: 210px;
            height: 290px;
            object-fit: cover;
            border-radius: 5px;
            box-shadow: 0px 0px 10px 0px rgba(232, 232, 232, 1);
            margin-bottom: 30px;
            margin-right: 30px;

            &:nth-child(3n) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.dialog-img {
  img {
    width: 100% !important;
  }
}
</style>
